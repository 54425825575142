@import '~antd/dist/antd.css';
@import '~@ant-design/pro-table/dist/table.css';
body {
  font-family: "Helvetica Neue",Helvetica,Tahoma,Arial,sans-serif;
  font-size: 13px;
  line-height: 1.53846154;
  color: #141414;
  background-color: #F0F2F5;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
}


.leftSider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 999;
  box-shadow: 2px 0 6px rgba(0,21,41,.35);
}
/* 二级菜单 */
.submenu {
  /*background: #e5e5e5;*/
  border-radius: 0px;
  margin-bottom: 0px;
  /*padding-bottom: 10px;*/
  border-width: 0;
  background: white;
}

.submenu .nav-secondary > li > a:hover {
  color: #0288d1;
  background: #F1F1F1;
  border-bottom: 2px solid #0288d1;
}
.submenu .nav-secondary > li > a:focus {
  color: #0288d1;
  /*border-bottom: 2px solid #0288d1;*/
  /*background: #F1F1F1;*/
}
.submenu .nav-secondary > li > .active {
  color: #0288d1;
  border-bottom: 2px solid #0288d1;
}

.nav-secondary > li > a {
  border: 0px;
}
.nav-secondary {
  background: white;
  padding-left: 15px;
}

.navbar-default {
  background-color: #F0F2F5;
}

.navbar {
  margin: 0px;
}


/* 表格数据筛选查询操作bar */
.filter-menu-bar {
  background-color: #f8fafe;
  padding: 5px 0px;
  /* margin-bottom: 10px;*/
  /*    border-bottom: 1px solid lightgrey;*/
}
.filter-menu-bar.navbar {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.filter-menu-bar .navbar-default {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}
.filter-menu-bar>nav>li.active {

}
.filter-menu-bar ul > li > a{
  padding-bottom: 6px;
  padding-top: 6px;
}
.filter-menu-bar ul > li{
}
.filter-menu-bar .navbar-right{
  margin-right: 0px;
}
.filter-menu-bar .pull-right li{
  list-style: none;
  height: 32px;
  float: left;
}
.filter-menu-bar .pull-right .btn-primary{
  height: 32px;
}
.filter-menu-bar .pull-right{
  margin-bottom: 0px;
  padding-right: 15px;
}
.filter-menu-bar .input-group{
  width: 250px;
  padding-left: 15px;
}
.filter-menu-bar .breadcrumb{
  margin-bottom: 0px;
  padding-top: 2px;
  padding-bottom: 6px;
}
.filter-menu-bar .input-group-first{
  padding-left: 15px;
}
.filter-menu-bar .input-group-first input{
  width: 100%;
}
.filter-menu-bar .input-group-one{
  padding-left: 15px;
}
.filter-menu-bar .input-group-one input{
  width: 100%;
}
.filter-menu-bar .input-groups{
  padding-left: 15px;
}
.filter-menu-bar .query-conditions-first{
  margin-left: 15px;
}
.filter-menu-bar .query-conditions-one{
  margin-left: 5px;
}
.filter-menu-bar .operation-one{
  padding-left: 5px;
}
.filter-menu-bar input{
  /*height: 32px;*/
  padding: 5px 8px;
  /*font-size: 13px;*/
  line-height: 1.53846154;
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  /*border: 1px solid #ccc;*/
  border-radius: 4px;
}


/* 主要内容区域 */
.content-layout {
  padding-left: 130px;
  padding-top: 50px;
}

.content-container {
  margin: 10px;
  background-color: white;
}
.content {
  /*    padding: 10px;*/
  padding: 10px 15px;
  background: white;
}
.content-big {
  /*    padding: 10px;*/
  padding: 15px 15px;
  background: #F0F2F5;
}

.nav-tabs {
  margin: 15px 0;
}

.devices-list {
  padding: 10px;
}
.hendel{
  display: none !important;
}
.QRCodeA:hover{
  cursor:pointer;
}
/*
.hide {
    background-color: red;
}
*/
.marginLeft{
  margin-left: 220px;
}
.marginLeft2{
  margin-left: 80px;
}

.ant-layout-content{
  margin-top: 51px !important;
}
.titleText{
  color: #fff ;
  padding-top: 12px ;
  padding-left: 4px ;
  overflow: hidden ;
  height: 30px;
  text-align: center;

}
.ant-layout-header{
  line-height:2
}

.table {
  word-break: break-all !important;
}
.ulli {
  padding-left: 20px;
}
.ulli li {
  padding-right: 20px;
}
.titleText{
  color: #fff ;
  padding-top: 12px ;
  padding-left: 4px ;
  overflow: hidden ;
  height: 30px;
  text-align: center;

}
.ant-list-header, .ant-list-footer {
   padding-top: 0px;
   padding-bottom: 0px;
}

/*pro*/
.ant-pro-table .ant-card {
  background: transparent;
}
.ant-pro-table .ant-table-wrapper {
  background: white;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
}

.ant-pro-table-toolbar {
  padding: 0;
  height: 48px;
}
.ant-pro-table-toolbar-title {
  display: none;
}
.ant-pro-table-toolbar-option {
  flex: 1;
}
.ant-pro-table-toolbar-option > .ant-space {
  flex: 1;
}
.ant-pro-table-toolbar-option > .ant-space > .ant-space-item:first-child {
  display: flex;
  flex: 1;
}



.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}
.ant-pro-table .ant-table-content tr:first-child>th:first-child, .ant-pro-table .ant-table-row .ant-table-cell:first-child {
  padding-left: 24px;
}


.ant-pro-table .ant-card-body{
  padding:0
}
tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
/*pro*/