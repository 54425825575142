.login-bg {
    background-image: url('../../img/login_bg.png');
    background-size: cover;
}
.slogan {
    color: white;
}
.slogan h1 {
    font-size: 4em;
    color: white;
}
.login-block {
    background: transparent;
    background-color: rgba(255,255,255,0.8);
    border: 1px solid #e1e7e9;
    box-shadow: 1px 1px 10px #cacaca;
    text-align: center;
    border-radius: 5px;
}
.login-div-bg{
    width: 440px;
    background: rgba(255, 255, 255, 0.27);
    opacity: 1;

}

.btn-home{
    width: 80%;
    height: 37px;
    line-height: 50%;
    color: #fff;
    background-color: #0271ae;
    border: 1px solid #0271ae;
}


/*.login-div-count {*/
/*    margin-top: 30px;*/
/*}*/

.login-div-count-div {
    margin-top: 10px;
    margin-bottom:15px;
    display: flex;
    right: auto;
    align-items: center;
    justify-content: center;
}
.login-div-count-div>a{
    color:red;
    text-decoration: none;
    padding-left:64%;
    display: block;
}
.login-div-forget{
    display: flex;
    margin-top: 2px;
    /*margin-bottom:1px;
    display: flex;
    right: auto;
    align-items: center;
    justify-content: center;*/
}
.login-div-checkbox{
    display: flex;
    flex:1;
    /*justify-content: center;*/
    align-items: center;
    left:11%;
    /*margin-left: 5%;*/
}
.login-div-forget>a{
    color:red;
    text-decoration: none;
    /*margin-left:144px;*/
    display: flex;
    flex:1;

    justify-content: center;
    align-items: center;
    /*right:11%;*/

    /*float: right;*/
    margin-left: 31%;

}
/*.login-div-checkbox>label{*/
    /*margin-left:15px;*/
/*}*/


.login-div-count-div input {
    width: 80%;
}
.captcha-input{
    width: 80%;
    display: flex;
}

.login-div-logimg{
    width: 100%;
}
.login-div-img {
    height: 50px;
    margin-top:20px;
    /*background: require("../img/loginbg.jpg");*/

}

.footer {
    text-align: center;
    color: white;
}

.cover {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    margin: auto;
    /*background: rgba(0, 0, 0, 0.7);*/
    color: white;
    /*z-index: 10000;*/
}

.cover div {
    margin: auto;
    padding: 0px;
    display: flex;

}

.cover div > .icon {
    display: block;
    height: 50px;
    margin: 0 auto;
    font-size: 42px;
    line-height: 50px;
    padding: 0px;
}




/*滑动验证码*/
.code_bg{
    position: fixed;
    top:0;
    left: 0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,.5);
    z-index: 99;
}
.icon-login-bg{
    /*background-image: url(../img/icon/loginicon.png);*/
    background-repeat: no-repeat;
}
.code-con{
    position: absolute;
    top:100px;
    width: 320px;
    left: 50%;
    margin-left: -160px;
    background-color: #fff;
    z-index: 100;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.code-img{
    margin: 5px 5px;
    padding: 5px 5px;
    background-color: #f5f6f7;
}
.code-img img{
    display: block;
}
.icon-w-25{
    display: inline-block;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
}
.icon-push{
    cursor: pointer;
    background-position: -149px -95px;
}
.code-push{
    height: 25px;
}
.code-btn{
    position: relative;
    height: 30px;
    text-align: center;
    color: #999;
    margin: 10px 10px;
    box-sizing: border-box;
    background-color: #f5f6f7;
    border-radius: 15px;
    border: 1px solid #e1e1e1;
}
.code-btn-m{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f6f7;
    border: 1px solid #e1e1e1;
    z-index: 5;
    top:-8px;
    left: 10px;
    box-shadow: 0 0 3px #ccc;
    cursor: pointer;
    background-position: -63px 10px;
}
.code-btn-img{
    /*background-image:url(../img/icon/codejt.png);*/
    background-repeat: no-repeat;
}
.code-btn-img.active{
    background-position: -134px 10px;
}
.code-btn-img.error{
    background-position: 8px 10px;
}
.code-img-con{
    position: relative;
}
.code-mask{
    position: absolute;
    top:0;
    left: 0;
    z-index: 10;
}
.code-tip{
    padding-left: 10px;
    font-size: 12px;
    color: #999;
}
.code-tip-red{
    color: red;
}
.code-tip-green{
    color: green;
}








/*拖动验证码*/
#drag{
    position: relative;
    background-color: #e8e8e8;
    width: 300px;
    height: 34px;
    line-height: 34px;
    text-align: center;
}
#drag .handler{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 32px;
    border: 1px solid #ccc;
    cursor: move;
}
.handler_bg{
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==") no-repeat center;
}
.handler_ok_bg{
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==") no-repeat center;
}
#drag .drag_bg{
    background-color: #7ac23c;
    height: 34px;
    width: 0px;
}
#drag .drag_text{
    position: absolute;
    top: 0px;
    width: 300px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -o-user-select:none;
    -ms-user-select:none;
}
